import { useActor } from "@xstate/react";
import { Link, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Brand from "src/components/Brand";
import NavPrimary from "src/components/NavPrimary";
import NavSecondary from "src/components/NavSecondary";
import { Base, BaseFooter, BaseHeader, BaseBody, CloseButton } from "./styles";
import { NavigationStateContextType } from "src/contexts/NavigationContext";
import { NAVIGATION_TAGS } from "src/machines/navigationMachine";
import { useApp } from "src/contexts/AppContext";
import { useTranslation } from "react-i18next";
import { COMMON } from "src/i18n/constants/namespaces";
interface NavigationProps {
  actor: NavigationStateContextType;
}
const Navigation = ({
  actor
}: NavigationProps) => {
  const {
    t: getTranslation
  } = useTranslation([COMMON]);
  const [state, send] = useActor(actor);
  const {
    appService
  } = useApp();
  const [appState] = useActor(appService);
  return <Base data-sentry-element="Base" data-sentry-component="Navigation" data-sentry-source-file="index.tsx">
      <BaseHeader data-sentry-element="BaseHeader" data-sentry-source-file="index.tsx">
        <CloseButton onClick={() => send({
        type: "INACTIVE"
      })} color="primary" data-sentry-element="CloseButton" data-sentry-source-file="index.tsx">
          <CloseIcon fontSize="medium" data-sentry-element="CloseIcon" data-sentry-source-file="index.tsx" />
        </CloseButton>
        <Brand onClick={() => send({
        type: "INACTIVE"
      })} data-sentry-element="Brand" data-sentry-source-file="index.tsx" />
      </BaseHeader>
      <BaseBody data-sentry-element="BaseBody" data-sentry-source-file="index.tsx">
        {state.hasTag(NAVIGATION_TAGS.PRIMARY) && <NavPrimary actor={actor} onNavSelect={() => send({
        type: "INACTIVE"
      })} />}
        {state.hasTag(NAVIGATION_TAGS.SECONDARY) && <NavSecondary actor={actor} onNavSelect={() => send({
        type: "INACTIVE"
      })} />}
      </BaseBody>
      <BaseFooter data-sentry-element="BaseFooter" data-sentry-source-file="index.tsx">
        <Link href={process.env.NEXT_PUBLIC_CHANGE_LOG_URL} target="_blank" underline="always" data-sentry-element="Link" data-sentry-source-file="index.tsx">
          <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            {getTranslation("versionHistory", {
            ns: COMMON
          })}
          </Typography>
        </Link>

        {appState.context.paymentStatus != null && !appState.context.paymentStatus.paymentExempt && appState.context.paymentStatus.isTrial && appState.context.paymentStatus.isActive && <Typography variant="h6">
              {getTranslation("freeTrialDaysLeft", {
          count: appState.context.paymentStatus.daysLeftInTrial ?? 0,
          ns: COMMON
        })}
            </Typography>}
      </BaseFooter>
    </Base>;
};
export default Navigation;