import styled from "@emotion/styled";
import Altai from "./assets/Altai.svg";
import { Typography } from "@mui/material";
import space from "src/utils/space";
import GlobalStyles from "src/components/Global";

export const Base = styled.main`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${space()};
  color: ${({ theme }) => theme.palette.text.primary};
  background-color: ${({ theme }) => theme.palette.background.default};
`;

export const BaseInner = styled.aside`
  position: relative;
`;

export const BaseHeader = styled.header`
  margin-bottom: ${space(1)};
  padding-bottom: ${space(1)};
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[600]};
`;
export const BaseBody = styled.div`
  margin-top: ${space(1)};
  display: flex;
  gap: ${space(0.5)};
`;

export const BaseButtonText = styled.button`
  font-size: 1em;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.palette.secondary.main};
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export const LinkButtonText = styled.a`
  font-size: 1em;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.palette.secondary.main};
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export const BaseTitle = styled(Typography)`
  margin: 0;
`;

export const BaseBrand = styled.span`
  position: relative;
  display: block;
  font-size: 1em;
  width: 4.375em;
  height: 1.975em;
  background-image: url(${Altai.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  cursor: pointer;
`;

export { GlobalStyles };
