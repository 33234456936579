import { ReactNode, useContext, createContext } from "react";
import WebSocketSubmissionService from "src/services/submissions/WebSocketSubmissionService";

/********************************************** */
// Context provider
/********************************************** */

interface WebSocketSubmissionProviderProps {
  children: ReactNode;
}
const WebSocketContext = createContext<WebSocketSubmissionService | null>(null);
const path = `${process.env.NEXT_PUBLIC_ALTAI_ROLES_API_URL}roles/submissionshub?roleId=[roleId]`;
const service = new WebSocketSubmissionService(path);
const WebSocketSubmissionProvider = ({
  children
}: WebSocketSubmissionProviderProps) => {
  return <WebSocketContext.Provider value={service} data-sentry-element="unknown" data-sentry-component="WebSocketSubmissionProvider" data-sentry-source-file="WebSocketSubmissionContext.tsx">
      {children}
    </WebSocketContext.Provider>;
};
const useWebSocketSubmission = () => {
  const context = useContext(WebSocketContext);
  if (typeof context === "undefined") {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};
export { useWebSocketSubmission, WebSocketSubmissionProvider };