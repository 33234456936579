import * as Sentry from "@sentry/react";
import { Trans, useTranslation } from "react-i18next";
import { PropsWithChildren } from "react";
import { Base, BaseTitle, BaseBrand, BaseHeader, BaseInner, BaseBody, BaseButtonText, GlobalStyles, LinkButtonText } from "./styles";
import { COMMON } from "src/i18n/constants/namespaces";
import { toHelloEmailAddress } from "src/utils/toHelloEmailAddress";
const FallbackComponent = ({
  resetError
}: {
  resetError: () => void;
}) => {
  const {
    t: getTranslation,
    i18n
  } = useTranslation([COMMON]);
  return <>
      <GlobalStyles data-sentry-element="GlobalStyles" data-sentry-source-file="index.tsx" />
      <Base data-sentry-element="Base" data-sentry-source-file="index.tsx">
        <BaseInner data-sentry-element="BaseInner" data-sentry-source-file="index.tsx">
          <BaseHeader data-sentry-element="BaseHeader" data-sentry-source-file="index.tsx">
            <BaseBrand data-sentry-element="BaseBrand" data-sentry-source-file="index.tsx" />
          </BaseHeader>
          <BaseBody data-sentry-element="BaseBody" data-sentry-source-file="index.tsx">
            <BaseTitle data-sentry-element="BaseTitle" data-sentry-source-file="index.tsx">
              <Trans i18nKey="fatalError.message" ns={COMMON} components={{
              tryAgain: <BaseButtonText onClick={() => resetError()} />,
              contactSupport: <LinkButtonText href={`mailto:${toHelloEmailAddress(i18n.resolvedLanguage)}?subject=${encodeURIComponent(getTranslation("fatalError.emailSubject"))}`} />
            }} data-sentry-element="Trans" data-sentry-source-file="index.tsx" />
            </BaseTitle>
          </BaseBody>
        </BaseInner>
      </Base>
    </>;
};
export default function ErrorBoundary({
  children
}: PropsWithChildren<{}>) {
  return <Sentry.ErrorBoundary showDialog={false} fallback={({
    resetError
  }) => <FallbackComponent resetError={resetError} />} beforeCapture={scope => {
    scope.setTag("location", "error_boundary");
  }} onError={error => {
    console.error(error);
  }} data-sentry-element="unknown" data-sentry-component="ErrorBoundary" data-sentry-source-file="index.tsx">
      {children}
    </Sentry.ErrorBoundary>;
}