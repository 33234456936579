import { CircularProgress } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { AnimatePresence } from "framer-motion";
import { Base, BaseContainer } from "./styles";
interface SavingSuccessProps {
  topOffset?: number;
  progressSize?: number;
  saving?: boolean;
  savingSuccess?: boolean;
}
const variants = {
  hidden: {
    opacity: 0,
    x: 0,
    scale: 0
  },
  enter: {
    opacity: 1,
    x: 0,
    scale: 1
  },
  exit: {
    opacity: 0,
    x: 0,
    scale: 0
  }
};
const SavingSuccess = ({
  topOffset = 0,
  saving = false,
  savingSuccess = false,
  progressSize = 20
}: SavingSuccessProps) => {
  return <Base active={saving || savingSuccess} data-sentry-element="Base" data-sentry-component="SavingSuccess" data-sentry-source-file="index.tsx">
      <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="index.tsx">
        {saving && <BaseContainer style={{
        top: topOffset
      }} key="saving" initial="hidden" animate="enter" exit="exit" variants={variants} transition={{
        type: "spring",
        duration: 0.3
      }}>
            <CircularProgress size={progressSize} />
          </BaseContainer>}
        {savingSuccess && <BaseContainer style={{
        top: topOffset
      }} key="savingSuccess" initial="hidden" animate="enter" exit="exit" variants={variants} transition={{
        type: "spring",
        duration: 0.3
      }}>
            <DoneIcon />
          </BaseContainer>}
      </AnimatePresence>
    </Base>;
};
export default SavingSuccess;