import {
    HttpTransportType,
    HubConnection,
    HubConnectionBuilder,
  } from "@microsoft/signalr";
import AgentBadgeCounts from "./entities/AgentBadgeCounts";
import AgentSubmissionPush from "./entities/SubmissionPush";
    
  // Event type
  export type WebSocketEvent = {
    badgeCount: AgentBadgeCounts;
  };
  
  export type SubscriberItem = {
    id: string;
    callback: (event: WebSocketEvent) => void;
  };
  
  class WebSocketSubmissionService {
    /********************************************** */
    // vars
    /********************************************** */
    wsClient: HubConnection | null = null;
    url: string | null = "";
    subscribers: SubscriberItem[] = [];
  
    /********************************************** */
    // Constructor
    /********************************************** */
    constructor(url: string) {
      this.url = url;
    }
  
    /********************************************************************** */
    // Private
    /********************************************************************** */
  
    /********************************************** */
    // Transform response
    /********************************************** */
    private _toPushData(data: any): AgentSubmissionPush | null {
      if (data === null) return null;
      return {
        badgeCount: {
          submitted: data?.submitCount ?? 0,
        },
      };
    }
  
    /********************************************** */
    // Initiate connection
    /********************************************** */
  
    private _init(url: string): HubConnection {
      return new HubConnectionBuilder()
        .withUrl(url, {
          transport: HttpTransportType.WebSockets,
          withCredentials: true,
        })
        .withAutomaticReconnect()
        .build();
    }
  
    /********************************************** */
    // Event - Initial connection
    /********************************************** */
    private _onIndividualConnectionHandler = () => {
      console.log("Connected to WebSocket");
    };
  
    /********************************************** */
    // Event - Data update
    /********************************************** */
    private _onReceiveDataHandler =
      (callback: (event: WebSocketEvent) => void) => (data: any) => {
        const pushData = this._toPushData(data);
        if (pushData === null) return;
  
        // Notify subscribers
        callback({
          ...pushData,
        });
      };
  
    /********************************************** */
    // Event - Connection closed
    /********************************************** */
    private _onCloseHandler = (error: any) => {
      console.warn("WebSocket connection closed", error);
    };
  
    /********************************************** */
    // Build the correct WebSocket URL with RoleID & AgentID
    /********************************************** */
    private _toPath(url: string, roleId: string, userId: string): string {
      return url.replace("[roleId]", roleId).replace("[userFirBaseId]", userId);
    }
    /********************************************************************** */
    // Public
    /********************************************************************** */
  
    /********************************************** */
    // Initiate connection
    /********************************************** */
    public async connect(
      roleId: string,
      userId: string,
      callback: (evt: WebSocketEvent) => void
    ): Promise<void> {
      // Validate input
      if (!roleId || !userId) {
        throw new Error("roleId and userId are required.");
      }
      if (!this.url) {
        throw new Error("No WebSocket URL provided.");
      }
  
      // Construct the WebSocket URL with roleId and agentId
      const path = this._toPath(this.url, roleId, userId);
      this.wsClient = this._init(path);
  
      // Register event handlers
      this.wsClient.on(
        "onIndividualConnection",
        this._onIndividualConnectionHandler
      );
      this.wsClient.on("update-badge-counts", this._onReceiveDataHandler(callback));
      this.wsClient.onclose(this._onCloseHandler);
  
      // Start connection
      try {
        await this.wsClient.start();
        console.log(`WebSocket connected for role ${roleId} and userId ${userId}`);
      } catch (error) {
        console.error("WebSocket connection error:", error);
      }
    }
  
    /********************************************** */
    // Disconnect
    /********************************************** */
    public async disconnect(
      callback: (evt: WebSocketEvent) => void
    ): Promise<void> {
      if (this.wsClient === null) return;
  
      // Remove event listeners
      this.wsClient.off(
        "onIndividualConnection",
        this._onIndividualConnectionHandler
      );
      this.wsClient.off("update-badge-counts", this._onReceiveDataHandler(callback));
  
      // Close connection
      try {
        await this.wsClient.stop();
        console.log("WebSocket disconnected.");
      } catch (error) {
        console.error("Error while disconnecting WebSocket:", error);
      }
  
      this.wsClient = null;
    }
  }
  
  export default WebSocketSubmissionService;
  