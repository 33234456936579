import { ReactNode, useContext, createContext } from "react";
import WebSocketSessionService from "src/services/sessions/WebSocketSessionService";

/********************************************** */
// Context provider
/********************************************** */

interface WebSocketSessionProviderProps {
  children: ReactNode;
}
const WebSocketContext = createContext<WebSocketSessionService | null>(null);
const path = `${process.env.NEXT_PUBLIC_ALTAI_ROLES_API_URL}roles/sessionshub?timelineId=[timelineId]`;
const service = new WebSocketSessionService(path);
const WebSocketSessionProvider = ({
  children
}: WebSocketSessionProviderProps) => {
  return <WebSocketContext.Provider value={service} data-sentry-element="unknown" data-sentry-component="WebSocketSessionProvider" data-sentry-source-file="WebSocketSessionContext.tsx">
      {children}
    </WebSocketContext.Provider>;
};
const useWebSocketSession = () => {
  const context = useContext(WebSocketContext);
  if (typeof context === "undefined") {
    throw new Error("useWebSocketSession must be used within a WebSocketSessionProvider");
  }
  return context;
};
export { useWebSocketSession, WebSocketSessionProvider };