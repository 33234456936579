import { ReactNode, useContext, createContext } from "react";
import WebSocketCdStatusReportTapesService from "src/services/webSockets/cdStatusReportTapes/WebSocketCdStatusReportTapesService";
interface WebSocketCdStatusReportTapesProviderProps {
  children: ReactNode;
}
const WebSocketContext = createContext<WebSocketCdStatusReportTapesService | null>(null);
const path = `${process.env.NEXT_PUBLIC_ALTAI_ROLES_API_URL}roles/cdstatusreporttapeshub?projectId=[projectId]`;
const service = new WebSocketCdStatusReportTapesService(path);
const WebSocketCdStatusReportTapesProvider = ({
  children
}: WebSocketCdStatusReportTapesProviderProps) => {
  return <WebSocketContext.Provider value={service} data-sentry-element="unknown" data-sentry-component="WebSocketCdStatusReportTapesProvider" data-sentry-source-file="WebSocketCdStatusReportTapesContext.tsx">
      {children}
    </WebSocketContext.Provider>;
};
const useWebSocketCdStatusReportTapes = () => {
  const context = useContext(WebSocketContext);
  if (typeof context === "undefined") {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};
export { useWebSocketCdStatusReportTapes, WebSocketCdStatusReportTapesProvider };