import { useRouter } from "next/router";
import { ReactNode } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Brand from "src/components/Brand";
import { Base, BaseLeading, BaseBody, BaseActions, BaseMenu, BaseGroup } from "./styles";
interface HeaderBarProps {
  breadcrumb?: ReactNode;
  breadcrumbActive?: boolean;
  actions?: ReactNode;
  onOpen: () => void;
  disableHamburger?: boolean;
}
const HeaderBar = ({
  onOpen,
  breadcrumb,
  actions,
  breadcrumbActive = true,
  disableHamburger
}: HeaderBarProps) => {
  const router = useRouter();
  return <Base data-sentry-element="Base" data-sentry-component="HeaderBar" data-sentry-source-file="index.tsx">
      <BaseGroup data-sentry-element="BaseGroup" data-sentry-source-file="index.tsx">
        <BaseLeading disableHamburger={disableHamburger} data-sentry-element="BaseLeading" data-sentry-source-file="index.tsx">
          {!disableHamburger && <BaseMenu>
              <IconButton onClick={() => onOpen()} color="primary">
                <MenuIcon fontSize="medium" />
              </IconButton>
            </BaseMenu>}
          <Brand onClick={() => router.push("/")} data-sentry-element="Brand" data-sentry-source-file="index.tsx" />
        </BaseLeading>
        <BaseBody active={breadcrumbActive} data-sentry-element="BaseBody" data-sentry-source-file="index.tsx">{breadcrumb}</BaseBody>
      </BaseGroup>
      <BaseActions data-sentry-element="BaseActions" data-sentry-source-file="index.tsx">{actions}</BaseActions>
    </Base>;
};
export default HeaderBar;