import { ReactNode } from "react";
import { Base, Header, HeaderInner, Body, BodyInner, NavOverlay, Nav, Stage } from "./styles";
interface LayoutDashboardBaseProps {
  active?: boolean;
  children?: ReactNode;
  navigation?: ReactNode;
  header?: ReactNode;
  onClose: () => void;
}
const LayoutDashboardBase = ({
  active = false,
  children,
  header,
  navigation,
  onClose
}: LayoutDashboardBaseProps) => {
  return <Base data-sentry-element="Base" data-sentry-component="LayoutDashboardBase" data-sentry-source-file="index.tsx">
      <Header data-sentry-element="Header" data-sentry-source-file="index.tsx">
        <HeaderInner data-sentry-element="HeaderInner" data-sentry-source-file="index.tsx">{header}</HeaderInner>
      </Header>
      <Body data-sentry-element="Body" data-sentry-source-file="index.tsx">
        <BodyInner data-sentry-element="BodyInner" data-sentry-source-file="index.tsx">
          <Nav active={active!} data-sentry-element="Nav" data-sentry-source-file="index.tsx">{navigation}</Nav>
          <NavOverlay onClick={() => onClose()} active={active!} data-sentry-element="NavOverlay" data-sentry-source-file="index.tsx" />
          <Stage data-sentry-element="Stage" data-sentry-source-file="index.tsx">{children}</Stage>
        </BodyInner>
      </Body>
    </Base>;
};
export default LayoutDashboardBase;