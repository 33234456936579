import { StreamApp, Streami18n } from "react-activity-feed";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { useApp } from "src/contexts/AppContext";
import { useActor, useMachine } from "@xstate/react";
import notificationMachine from "src/machines/notificationMachine";
import NotificationStreamItem from "src/components/NotificationStreamItem";
import "react-activity-feed/dist/index.css";
import { Base, BaseDropdown, BaseHeaderLink, BaseNotifier, BasePlaceHolder, BasePrimaryTitle } from "./styles";
import toStreamItem from "../NotificationStreamItem/utils/toStreamItem";
import { useRef, useState } from "react";
import SavingSuccess from "../SavingSuccess";
import i18next from "i18next";
import { COMMON } from "src/i18n/constants/namespaces";
import { LoadMoreButton } from "react-activity-feed/dist/components/LoadMoreButton";
NotificationStreamItem;
const NotificationStream = () => {
  const {
    appService
  } = useApp();
  const [appState] = useActor(appService);
  const [state, send] = useMachine(notificationMachine, {
    devTools: true,
    context: {
      userType: appState.context?.auth?.userType ?? null
    },
    actions: {
      notifyMarkAllReadComplete: () => {
        setShowMarkAllCompleted(true);
        setTimeout(() => {
          setShowMarkAllCompleted(false);
        }, 500);
      }
    }
  });
  const {
    messagingUserToken
  } = appState.context;
  const markAllRead = () => {
    send("MARK_ALL_AS_READ");
  };
  const [showMarkAllCompleted, setShowMarkAllCompleted] = useState(false);
  const isSavingMarkAll = state.matches("markAllMessageAsRead");
  const [readAndSeenOptions, setReadAndSeenOptions] = useState({
    read: false,
    seen: false
  });
  const [showMarkAllAsReadLink, setShowMarkAllAsReadLink] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  //At time of writing, Streami18n have built-in translations for
  //en, nl, ru, tr, fr, hi, it, es. If we need other languages
  //we can use registerTranslation. see https://getstream.github.io/react-activity-feed/i18n.
  const streamLocal = new Streami18n({
    language: i18next.resolvedLanguage?.split("-")[0]
  });
  return <>
      <Base data-sentry-element="Base" data-sentry-source-file="index.tsx">
        {messagingUserToken !== null && <StreamApp apiKey={process.env.NEXT_PUBLIC_GETSTREAM_KEY!} appId={process.env.NEXT_PUBLIC_GETSTREAM_APP_ID!} token={messagingUserToken!} i18nInstance={streamLocal}>
            <BaseDropdown notify right Icon={() => <NotificationsNoneOutlinedIcon onClick={() => setReadAndSeenOptions({
          read: true,
          seen: true
        })} style={{
          marginTop: "0.25em",
          fill: "currentColor"
        }} />} width={330} Header={showMarkAllAsReadLink ? () => <BaseHeaderLink
        // @ts-ignore
        component="button" onClick={() => markAllRead()}>
                        <SavingSuccess topOffset={10} saving={isSavingMarkAll} savingSuccess={showMarkAllCompleted} />
                        <BasePrimaryTitle variant="h6">
                          {i18next.t("notificationStream.link", {
              context: "markAllAsRead",
              ns: COMMON
            })}
                        </BasePrimaryTitle>
                      </BaseHeaderLink> : null} Notifier={BaseNotifier} Placeholder={() => <BasePlaceHolder>
                  <BasePrimaryTitle variant="body1">
                    {i18next.t("notificationStream.noNewNotifications", {
              ns: COMMON
            })}
                  </BasePrimaryTitle>
                </BasePlaceHolder>} Group={group => {
          const item = toStreamItem(group.activityGroup);
          if (item === null) {
            return null;
          } else {
            setShowMarkAllAsReadLink(true);
          }
          return <NotificationStreamItem {...item} />;
        }} options={{
          mark_read: readAndSeenOptions.read,
          mark_seen: readAndSeenOptions.seen
        }} />
          </StreamApp>}
      </Base>
    </>;
};
export default NotificationStream;