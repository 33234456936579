const toDateOnly = (dateString: string | undefined): Date | null => {
  if (!dateString) return null;
  const date = new Date(dateString);

  const utcDateOfMonth = date.getUTCDate();
  const utcMonth = date.getUTCMonth();
  const utcYear = date.getUTCFullYear();

  date.setDate(utcDateOfMonth);
  date.setMonth(utcMonth);
  date.setFullYear(utcYear);

  return date;
};

export default toDateOnly;
