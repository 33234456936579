import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
} from "@microsoft/signalr";
import CdStatusReportTapesPushType from "./entities/CdStatusReportTapesPushType";
import TapesPush from "./entities/TapesPush";

export type WebSocketEvent = {
  type: CdStatusReportTapesPushType;
  tapeId: string;
  cdSetTapesAsViewed?: boolean | null;
  cdMarkedTapeAsHidden?: boolean | null;
};

export type SubscriberItem = {
  id: string;
  callback: (event: WebSocketEvent) => void;
};

class WebSocketCdStatusReportTapesService {
  wsClient: HubConnection | null = null;
  url: string | null = "";
  subscribers: SubscriberItem[] = [];

  constructor(url: string) {
    this.url = url;
  }

  private _toPushData(data: any): TapesPush | null {
    if (data === null) return null;
    return {
      type: data?.type ?? CdStatusReportTapesPushType.CdSetTapesAsViewedUpdated,
      tapeId: data?.tapeId,
      cdSetTapesAsViewed: data?.cdSetTapesAsViewed,
      cdMarkedTapeAsHidden: data?.cdMarkedTapeAsHidden,
    };
  }

  private _init(url: string): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .build();
  }

  private _onIndividualConnectionHandler = (connectionId?: string) => {
    console.log(`CdStatusReportTapes WebSocket Connected: ${connectionId}`);
  };

  private _onReceiveDataHandler =
    (callback: (event: WebSocketEvent) => void) => (data: any) => {
      const pushData = this._toPushData(data);
      if (pushData === null) return;
      // Notify subscribers
      callback({ ...pushData, type: pushData.type });
    };

  private _onCloseHandler = (error: any) => {};

  private _toPath(url: string, projectId: string): string {
    return url.replace("[projectId]", projectId);
  }

  public async connect(
    projectId: string,
    callback: (evt: WebSocketEvent) => void
  ): Promise<void> {
    if (typeof projectId !== "string") {
      throw new Error("projectId must be a string");
    }
    if (this.url === "") {
      throw new Error("No url provided");
    }

    const path = this._toPath(this.url!, projectId);
    this.wsClient = this._init(path);
    this.wsClient.on(
      "onIndividualConnection",
      this._onIndividualConnectionHandler
    );
    this.wsClient.on("receiveData", this._onReceiveDataHandler(callback));
    this.wsClient.onclose(this._onCloseHandler);

    await this.wsClient.start();
  }

  public async disconnect(
    callback: (evt: WebSocketEvent) => void
  ): Promise<void> {
    if (this.wsClient === null) return;

    this.wsClient.off(
      "onIndividualConnection",
      this._onIndividualConnectionHandler
    );
    this.wsClient.off("receiveData", this._onReceiveDataHandler(callback));

    await this.wsClient.stop();
    this.wsClient = null;
  }
}

export default WebSocketCdStatusReportTapesService;
