import { useRef } from "react";
import { useActor } from "@xstate/react";
import { motion } from "framer-motion";
import Link from "next/link";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NavLink from "src/components/NavLink";
import { Base, BaseHeader, BaseBody, IconButton, BaseList, Pong, Title } from "./styles";
import { NavigationStateContextType } from "src/contexts/NavigationContext";
import SkeletonLoader from "src/components/SkeletonLoader";
import generateList from "src/utils/generateList";
import { NavItem } from "src/types/NavItem";
import { useTranslation } from "react-i18next";
import { COMMON } from "src/i18n/constants/namespaces";
interface NavSecondaryProps {
  actor: NavigationStateContextType;
  onNavSelect: () => void;
}
const NavSecondary = ({
  onNavSelect,
  actor
}: NavSecondaryProps) => {
  const {
    t: getTranslation
  } = useTranslation([COMMON]);
  const baseRef = useRef<HTMLDivElement>(null);
  const [state, send] = useActor(actor);
  const {
    context: {
      secondary,
      primary
    }
  } = state;

  // Handler to determine view values to update state
  const onUpdateHandler = (posY: number) => {
    const offset = 11;
    const parentY = baseRef.current!.getBoundingClientRect().top;
    send({
      type: "PONG_POSITION_UPDATE",
      pos: posY - parentY + offset
    });
  };

  // Generate list of nav items in loading state (skeleton)
  const list = !secondary.ready ? (generateList({}, 3) as NavItem[]) : secondary.list;
  if (typeof primary.active.href === 'undefined') {
    // This is expected to be populated
    console.error('navigation::primary::undefined::href', primary);
  }
  if (typeof secondary.root?.href === 'undefined') {
    // Note this may not be populated
    console.info('navigation::secondary::undefined::href', secondary);
  }
  return <Base data-sentry-element="Base" data-sentry-component="NavSecondary" data-sentry-source-file="index.tsx">
      <BaseHeader data-sentry-element="BaseHeader" data-sentry-source-file="index.tsx">
        <motion.div initial={{
        x: 16,
        opacity: 0
      }} transition={{
        type: "spring",
        duration: 0.5
      }} animate={{
        x: 0,
        opacity: 1
      }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
          <Link href={primary.active?.href} legacyBehavior data-sentry-element="Link" data-sentry-source-file="index.tsx">
            <IconButton color="primary" data-sentry-element="IconButton" data-sentry-source-file="index.tsx">
              <ArrowBackIcon fontSize="medium" data-sentry-element="ArrowBackIcon" data-sentry-source-file="index.tsx" />
            </IconButton>
          </Link>
        </motion.div>
        <motion.div initial={{
        opacity: 0
      }} transition={{
        type: "spring",
        duration: 0.5
      }} animate={{
        opacity: 1
      }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
          <SkeletonLoader height={35} loading={!secondary.ready} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx">
            <Link href={secondary.root?.href ?? ""} legacyBehavior data-sentry-element="Link" data-sentry-source-file="index.tsx">
              <Title onClick={() => onNavSelect()} data-sentry-element="Title" data-sentry-source-file="index.tsx">
                {secondary.root?.labelKey ? getTranslation(`navigation.${secondary.root?.labelKey}`, {
                pathName: secondary.root?.pathLabel,
                ns: COMMON,
                defaultValue: secondary.root?.pathLabel
              }) : ""}
              </Title>
            </Link>
          </SkeletonLoader>
        </motion.div>
      </BaseHeader>
      <motion.div initial={{
      opacity: 0
    }} transition={{
      type: "spring",
      duration: 1
    }} animate={{
      opacity: 1
    }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        <BaseBody ref={baseRef} data-sentry-element="BaseBody" data-sentry-source-file="index.tsx">
          <Pong animate={{
          y: secondary.posY,
          opacity: secondary.pongActive ? 1 : 0
        }} transition={{
          type: "spring",
          duration: 0.4
        }} data-sentry-element="Pong" data-sentry-source-file="index.tsx" />
          <BaseList data-sentry-element="BaseList" data-sentry-source-file="index.tsx">
            {list.map(item => <li key={item.id}>
                <SkeletonLoader height={50} width={100} loading={!secondary.ready}>
                  <NavLink variant="secondary" onUpdate={onUpdateHandler} onClick={onNavSelect} href={item?.href}>
                    {item.labelKey ? getTranslation(`navigation.${item.labelKey}`, {
                  pathName: item.pathLabel,
                  ns: COMMON,
                  defaultValue: item.pathLabel
                }) : ""}
                  </NavLink>
                </SkeletonLoader>
              </li>)}
          </BaseList>
        </BaseBody>
      </motion.div>
    </Base>;
};
export default NavSecondary;