import { ReactNode, useContext, createContext } from "react";
import WebSocketTapePanelService from "src/services/webSockets/tapePanel/WebSocketTapePanelService";
interface WebSocketTapePanelProviderProps {
  children: ReactNode;
}
const WebSocketContext = createContext<WebSocketTapePanelService | null>(null);
const path = `${process.env.NEXT_PUBLIC_ALTAI_EXTERNAL_API_URL}channel/tapepanelstarbatcheshub?projectId=[projectId]`;
const service = new WebSocketTapePanelService(path);
const WebSocketTapePanelProvider = ({
  children
}: WebSocketTapePanelProviderProps) => {
  return <WebSocketContext.Provider value={service} data-sentry-element="unknown" data-sentry-component="WebSocketTapePanelProvider" data-sentry-source-file="WebSocketTapePanelContext.tsx">
      {children}
    </WebSocketContext.Provider>;
};
const useWebSocketTapePanel = () => {
  const context = useContext(WebSocketContext);
  if (typeof context === "undefined") {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};
export { useWebSocketTapePanel, WebSocketTapePanelProvider };