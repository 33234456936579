import Link from "next/link";
import { Base, BrandLink, VisuallyHidden } from "./styles";
interface BrandProps {
  onClick?: () => void;
}
const Brand = ({
  onClick
}: BrandProps) => <Base data-sentry-element="Base" data-sentry-component="Brand" data-sentry-source-file="index.tsx">
    <Link href="#" legacyBehavior data-sentry-element="Link" data-sentry-source-file="index.tsx">
      <BrandLink onClick={evt => {
      evt.preventDefault();
      typeof onClick !== "undefined" ? onClick() : null;
    }} data-sentry-element="BrandLink" data-sentry-source-file="index.tsx">
        <VisuallyHidden data-sentry-element="VisuallyHidden" data-sentry-source-file="index.tsx">Home</VisuallyHidden>
      </BrandLink>
    </Link>
  </Base>;
export default Brand;