import { useTranslation } from "react-i18next";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { useApp } from "src/contexts/AppContext";
import { useActor } from "@xstate/react";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
const LanguagePicker = () => {
  const {
    i18n
  } = useTranslation();
  const {
    locales
  } = useRouter();
  const {
    appService
  } = useApp();
  const [appState, send] = useActor(appService);
  const handleChange = (value: string | undefined) => {
    if (value) {
      i18n.changeLanguage(value);
      send({
        type: "UPDATE_USER_LOCALE",
        selectedLocale: value
      });
    }
  };
  const [optionsOpen, setOptionsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLButtonElement | null>(null);
  const {
    artistHomePageDetails,
    agencyUserDetails,
    cdHomePageDetails
  } = appState.context;
  useEffect(() => {
    const locale = artistHomePageDetails?.defaultLocale ?? agencyUserDetails?.defaultLocale ?? cdHomePageDetails?.defaultLocale;
    if (locale && locale.length > 1 && i18n.resolvedLanguage !== locale) {
      i18n.changeLanguage(locale);
    }
  }, [artistHomePageDetails, agencyUserDetails, cdHomePageDetails]);
  return <>
      <IconButton aria-label="select language" onClick={() => setOptionsOpen(true)} ref={ref} data-sentry-element="IconButton" data-sentry-source-file="index.tsx">
        <LanguageIcon data-sentry-element="LanguageIcon" data-sentry-source-file="index.tsx" />
      </IconButton>
      <Menu open={optionsOpen} anchorEl={ref.current} onClose={() => setOptionsOpen(false)} anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }} transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }} data-sentry-element="Menu" data-sentry-source-file="index.tsx">
        {locales?.map(locale => <MenuItem key={locale} value={locale} selected={locale === i18n.resolvedLanguage} onClick={() => {
        handleChange(locale);
        setOptionsOpen(false);
      }}>
            <Typography textTransform="capitalize">
              {new Intl.DisplayNames(locale, {
            type: "language",
            languageDisplay: "standard"
          }).of(locale)}
            </Typography>
          </MenuItem>)}
      </Menu>
    </>;
};
export default LanguagePicker;