import { useActor } from "@xstate/react";
import { useRef } from "react";
import NavLink from "src/components/NavLink";
import { NavigationStateContextType } from "src/contexts/NavigationContext";
import { Pong, Base, BaseList, BaseInner } from "./styles";
import { useTranslation } from "react-i18next";
import { COMMON } from "src/i18n/constants/namespaces";
interface NavPrimaryProps {
  actor: NavigationStateContextType;
  onNavSelect: () => void;
}
const NavPrimary = ({
  onNavSelect,
  actor
}: NavPrimaryProps) => {
  const {
    t: getTranslation
  } = useTranslation([COMMON]);
  const navRef = useRef<HTMLDivElement>(null);
  const [state, send] = useActor(actor);
  const {
    context: {
      primary
    }
  } = state;

  // Handler to determine view values to update state
  const onUpdateHandler = (posY: number) => {
    const offset = 8;
    const parentY = navRef.current!.getBoundingClientRect().top;
    send({
      type: "PONG_POSITION_UPDATE",
      pos: posY - parentY + offset
    });
  };
  return <Base initial={{
    opacity: 0
  }} animate={{
    opacity: 1
  }} data-sentry-element="Base" data-sentry-component="NavPrimary" data-sentry-source-file="index.tsx">
      <BaseInner ref={navRef} data-sentry-element="BaseInner" data-sentry-source-file="index.tsx">
        <Pong animate={{
        y: primary.posY,
        opacity: primary.pongActive ? 1 : 0
      }} transition={{
        type: "spring",
        duration: 0.4
      }} data-sentry-element="Pong" data-sentry-source-file="index.tsx" />
        <BaseList data-sentry-element="BaseList" data-sentry-source-file="index.tsx">
          {primary.list.map(item => {
          if (typeof item.href === 'undefined') {
            console.error('navigation::undefined::href', item);
          }
          return <li key={item.id}>
                <NavLink onUpdate={onUpdateHandler} onClick={onNavSelect} href={item?.href}>
                  {item.labelKey ? getTranslation(`navigation.${item.labelKey}`, {
                pathName: item.pathLabel,
                ns: COMMON,
                defaultValue: item.pathLabel
              }) : ""}
                </NavLink>
              </li>;
        })}
        </BaseList>
      </BaseInner>
    </Base>;
};
export default NavPrimary;