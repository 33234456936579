// TODO - Follow up on what other types of sessions are available
import { formatRelative } from "date-fns";
export type StreamItemDetail = {
  role_name: string;
  date: string;
  time: string;
  project_name: string;
  artist_name: string;
  agent_name: string;
  session_url: string;
  message?: string;
  session_type?: string;
  session_format?: string;
  decline_reason?: string;
  list_name?: string;
  folder_name?: string;
  channel_name?: string;
  unviewed_ndas?: number;
  unviewed_submissions?: number;
  unviewed_self_tapes?: number;
  link_to?: string;
};
export type StreamItem = {
  id: string;
  isRead: boolean;
  isSeen: boolean;
  verb: "client_commented_on_artist_tape_notification" | "client-commented-on-artist-list-notification" | "signed-nda-to-review-notification" | "new_role_submissions_for_review_notification" | "unviewed_self_tapes_notification";
  detail: StreamItemDetail;
  createdAt: string;
};
import { Base, BaseTitle, BaseTime, BaseGroupPrimary, BaseGroupSecondary, BaseNotRead } from "./styles";
import toDate from "src/utils/toDate";
import { Link } from "@mui/material";
import { tolinkTarget } from "./utils/toLinkTarget";
import i18next from "i18next";
import { COMMON } from "src/i18n/constants/namespaces";
import toI18nContextString from "src/utils/toI18nContextString";
import { toFnsLocale } from "src/utils/toFnsLocale";
interface NotificationStreamItemProps extends StreamItem {}
const NotificationStreamItem = ({
  verb,
  isRead,
  detail,
  createdAt
}: NotificationStreamItemProps) => {
  const message = i18next.t("notificationStream.streamMessage", {
    context: toI18nContextString(verb),
    count: verb === "new_role_submissions_for_review_notification" ? detail.unviewed_submissions : verb === "unviewed_self_tapes_notification" ? detail.unviewed_self_tapes : undefined,
    detail: detail,
    ns: COMMON,
    defaultValue: ""
  });
  const relativeTime = formatRelative(toDate(createdAt)!, new Date(), {
    locale: toFnsLocale(i18next.resolvedLanguage)
  });
  const linkTarget = tolinkTarget(verb);
  return <>
      {message !== "" && (detail.link_to === undefined || detail.link_to === null) && <Link href="#">
            <Base>
              <BaseGroupPrimary>
                <BaseTitle variant="h6">{message}</BaseTitle>
                <BaseTime variant="caption">{relativeTime}</BaseTime>
              </BaseGroupPrimary>
              <BaseGroupSecondary>
                <BaseNotRead active={!isRead} />
              </BaseGroupSecondary>
            </Base>
          </Link>}
      {message !== "" && detail.link_to !== undefined && detail.link_to !== null && <Link href={detail.link_to!} target={linkTarget} underline="none">
            <Base>
              <BaseGroupPrimary>
                <BaseTitle variant="h6">{message}</BaseTitle>
                <BaseTime variant="caption">{relativeTime}</BaseTime>
              </BaseGroupPrimary>
              <BaseGroupSecondary>
                <BaseNotRead active={!isRead} />
              </BaseGroupSecondary>
            </Base>
          </Link>}
    </>;
};
export default NotificationStreamItem;