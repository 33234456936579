import { NextPage } from "next";
import type { AppProps } from "next/app";
import { ElementType, useEffect } from "react";
import { AnimatePresence, domAnimation, LazyMotion, motion } from "framer-motion";
import { CacheProvider, EmotionCache, ThemeProvider as EmotionProvider } from "@emotion/react";
import { inspect } from "@xstate/inspect";
import { ThemeProvider } from "@mui/system";
import { AppProvider } from "src/contexts/AppContext";
import theme from "src/theme";
import Global from "src/components/Global";
import LayoutDashboard from "src/components/LayoutDashboard";
import createEmotionCache from "src/utils/createEmotionCache";
import UnknownState from "src/components/UnknownState";
import { NavigationProvider } from "src/contexts/NavigationContext";
import LayoutBackground from "src/components/LayoutBackground";
import { WebSocketSubmissionProvider } from "src/contexts/WebSocketSubmissionContext";
import * as ga from "../lib/analytics";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { LicenseInfo } from "@mui/x-license-pro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { WebSocketSessionProvider } from "src/contexts/WebSocketSessionContext";
import { StatusReportProvider } from "src/contexts/StatusReportContext";
import { WebSocketManageSessionProvider } from "src/contexts/WebSocketManageSessionContext";
import ApplicationInsightsProvider from "../src/contexts/ApplicationInsightsContext";
import { WebSocketCdStatusReportTapesProvider } from "src/contexts/WebSocketCdStatusReportTapesContext";
import ErrorBoundary from "src/structures/ErrorBoundary";
import i18nAltai from "src/i18n/i18nAltai";
import { I18nextProvider } from "react-i18next";
import { Suspense } from "react";
import { toFnsLocale } from "src/utils/toFnsLocale";
import { WebSocketTapePanelProvider } from "src/contexts/WebSocketTapePanelContext";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { Router } from "next/router";
import { WebSocketAgentSubmissionProvider } from "src/contexts/WebSocketAgentSubmissionContext";
if (typeof window !== "undefined" && process.env.NODE_ENV === "development") {
  inspect({
    iframe: false
  });
}
const clientSideEmotionCache = createEmotionCache();
export type NextPageWithLayout = NextPage & {
  Layout?: ElementType;
  Provider?: ElementType;
  SecondaryProvider?: ElementType;
};
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  emotionCache: EmotionCache;
};

// Tranistion
const variants = {
  hidden: {
    opacity: 0,
    x: 0
  },
  enter: {
    opacity: 1,
    x: 0
  },
  exit: {
    opacity: 0,
    x: 0
  }
};
interface DefaultProviderProps {
  children: React.ReactNode;
}

// Temporary wrapper to allow for custom provider
const DefaultProvider = ({
  children
}: DefaultProviderProps) => <>{children}</>;
function AltaiCasingApp(props: AppPropsWithLayout) {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps,
    router
  } = props;

  // Utilise pre-defined layout or use Dashboard
  const Layout = Component.Layout ?? LayoutDashboard;
  // Apply additional provider if defined
  const Provider = Component.Provider ?? DefaultProvider;
  // Apply secondary provider if defined (used to persist context between to pages where there's already a context uitlised)
  const SecondaryProvider = Component.SecondaryProvider ?? DefaultProvider;

  // Set MUI-X license key globally
  LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_LICENSE!);
  useEffect(() => {
    const handleRouteChange = (url: any) => {
      ga.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  // Post hog
  useEffect(() => {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://us.i.posthog.com",
      person_profiles: "identified_only",
      // or 'always' to create profiles for anonymous users as well
      // Enable debug mode in development
      loaded: posthog => {
        if (process.env.NODE_ENV === "development") posthog.debug();
      }
    });

    // Capture page views
    const handleRouteChange = () => posthog?.capture("$pageview");
    Router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);
  return <CacheProvider value={emotionCache} data-sentry-element="CacheProvider" data-sentry-component="AltaiCasingApp" data-sentry-source-file="_app.tsx">
      <AppProvider data-sentry-element="AppProvider" data-sentry-source-file="_app.tsx">
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={toFnsLocale(i18nAltai.resolvedLanguage)} data-sentry-element="LocalizationProvider" data-sentry-source-file="_app.tsx">
          <ApplicationInsightsProvider data-sentry-element="ApplicationInsightsProvider" data-sentry-source-file="_app.tsx">
            <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-source-file="_app.tsx">
              <I18nextProvider i18n={i18nAltai} data-sentry-element="I18nextProvider" data-sentry-source-file="_app.tsx">
                <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-source-file="_app.tsx">
                  {/* Note issues with mui styled, utilising emotion directly and leveraging theme values */}
                  <EmotionProvider theme={theme} data-sentry-element="EmotionProvider" data-sentry-source-file="_app.tsx">
                    <Suspense fallback="...loading" data-sentry-element="Suspense" data-sentry-source-file="_app.tsx">
                      <PostHogProvider client={posthog} data-sentry-element="PostHogProvider" data-sentry-source-file="_app.tsx">
                        <Global data-sentry-element="Global" data-sentry-source-file="_app.tsx" />
                        <UnknownState data-sentry-element="UnknownState" data-sentry-source-file="_app.tsx">
                          <WebSocketSessionProvider data-sentry-element="WebSocketSessionProvider" data-sentry-source-file="_app.tsx">
                            <WebSocketSubmissionProvider data-sentry-element="WebSocketSubmissionProvider" data-sentry-source-file="_app.tsx">
                            <WebSocketAgentSubmissionProvider data-sentry-element="WebSocketAgentSubmissionProvider" data-sentry-source-file="_app.tsx">
                              <WebSocketManageSessionProvider data-sentry-element="WebSocketManageSessionProvider" data-sentry-source-file="_app.tsx">
                                <WebSocketCdStatusReportTapesProvider data-sentry-element="WebSocketCdStatusReportTapesProvider" data-sentry-source-file="_app.tsx">
                                  <WebSocketTapePanelProvider data-sentry-element="WebSocketTapePanelProvider" data-sentry-source-file="_app.tsx">
                                    <SecondaryProvider data-sentry-element="SecondaryProvider" data-sentry-source-file="_app.tsx">
                                      <NavigationProvider data-sentry-element="NavigationProvider" data-sentry-source-file="_app.tsx">
                                        <Provider data-sentry-element="Provider" data-sentry-source-file="_app.tsx">
                                          <LayoutBackground data-sentry-element="LayoutBackground" data-sentry-source-file="_app.tsx" />
                                          <Layout data-sentry-element="Layout" data-sentry-source-file="_app.tsx">
                                            <LazyMotion features={domAnimation} data-sentry-element="LazyMotion" data-sentry-source-file="_app.tsx">
                                              <AnimatePresence exitBeforeEnter initial={false} onExitComplete={() => window.scrollTo(0, 0)} data-sentry-element="AnimatePresence" data-sentry-source-file="_app.tsx">
                                                <motion.div initial="hidden" animate="enter" exit="exit" variants={variants} key={router.route} transition={{
                                                  type: "spring",
                                                  duration: 0.3
                                                }} data-sentry-element="unknown" data-sentry-source-file="_app.tsx">
                                                  <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
                                                </motion.div>
                                              </AnimatePresence>
                                            </LazyMotion>
                                          </Layout>
                                        </Provider>
                                      </NavigationProvider>
                                    </SecondaryProvider>
                                  </WebSocketTapePanelProvider>
                                </WebSocketCdStatusReportTapesProvider>
                              </WebSocketManageSessionProvider>
                              </WebSocketAgentSubmissionProvider>
                            </WebSocketSubmissionProvider>
                          </WebSocketSessionProvider>
                        </UnknownState>
                      </PostHogProvider>
                    </Suspense>
                  </EmotionProvider>
                </ErrorBoundary>
              </I18nextProvider>
            </ThemeProvider>
          </ApplicationInsightsProvider>
        </LocalizationProvider>
      </AppProvider>
    </CacheProvider>;
}
export default AltaiCasingApp;