import { ReactNode, useContext, createContext } from "react";
import WebSocketAgentSubmissionService from "src/services/submissions/AgentSubmissions/WebSocketAgentSubmissionService";
import WebSocketSubmissionService from "src/services/submissions/WebSocketSubmissionService";

/********************************************** */
// Context provider
/********************************************** */

interface WebSocketAgentSubmissionProviderProps {
  children: ReactNode;
}
const WebSocketContext = createContext<WebSocketAgentSubmissionService | null>(null);
const path = `${process.env.NEXT_PUBLIC_ALTAI_ROLES_API_URL}roles/agentsubmissionshub?roleId=[roleId]&userFirBaseId=[userFirBaseId]`;
const service = new WebSocketAgentSubmissionService(path);
const WebSocketAgentSubmissionProvider = ({
  children
}: WebSocketAgentSubmissionProviderProps) => {
  return <WebSocketContext.Provider value={service} data-sentry-element="unknown" data-sentry-component="WebSocketAgentSubmissionProvider" data-sentry-source-file="WebSocketAgentSubmissionContext.tsx">
      {children}
    </WebSocketContext.Provider>;
};
const useWebSocketAgentSubmission = () => {
  const context = useContext(WebSocketContext);
  if (typeof context === "undefined") {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};
export { useWebSocketAgentSubmission, WebSocketAgentSubmissionProvider };