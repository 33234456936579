import type { ReactNode } from "react";
import { AnimatePresence } from "framer-motion";
import { Skeleton } from "@mui/material";
import { Motion } from "./styles";
interface SkeletonLoaderProps {
  loading: boolean;
  width?: number | string;
  height?: number | string;
  children: ReactNode;
}
const SkeletonLoader = ({
  loading,
  children,
  width,
  height
}: SkeletonLoaderProps) => <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-component="SkeletonLoader" data-sentry-source-file="index.tsx">
    {loading && <Motion initial={{
    opacity: 0
  }} animate={{
    opacity: 1
  }}
  // Add slight delay to display loader if it takes longer than 300ms
  transition={{
    delay: 0.3
  }} exit={{
    opacity: 0
  }}>
        <Skeleton width={width} height={height} />
      </Motion>}
    {!loading && <Motion initial={{
    opacity: 0
  }} animate={{
    opacity: 1
  }} exit={{
    opacity: 0
  }}>
        {children}
      </Motion>}
  </AnimatePresence>;
export default SkeletonLoader;